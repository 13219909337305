import { useMemo, useState } from 'react';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { Input, DatePicker, Select } from '@formily/antd-v5';
import { Button, Card, Drawer, Modal, message, Descriptions, Tag } from 'antd';
import type { DescriptionsProps } from 'antd';
import { AiOutlineDelete } from 'react-icons/ai';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { deleteOperLog, getOperLogList } from 'src/api/clients/system';
import { IGetLogsOperListReq, IOperLogItem } from 'src/api/types/system';

import { QueryTable, QueryTableColumnProps } from 'src/components/QueryTable';
import { schema, columns } from './schema';
import { StatusEnum } from 'src/api/types/common';
import { IClientInfo } from 'src/interface';

const SystemLogLoginTableName = 'SystemLogLoginTableName';

export default function SystemNotice() {
  const [open, { setTrue, setFalse }] = useBoolean(false);
  const { t } = useTranslation();
  const [operLog, setOperLog] = useState<IOperLogItem>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const getRolesTableList = useMemoizedFn(async (params: IGetLogsOperListReq) => {
    const { createTime, endTime } = params;
    const result = await getOperLogList({
      ...params,
      createTime: createTime ? dayjs(createTime).unix() : undefined,
      endTime: endTime ? dayjs(endTime).endOf('day').unix() : undefined,
    });
    const data = result.rows.map((item) => {
      return {
        ...item,
        client: item.client ? JSON.parse(item.client) : null,
      };
    });
    return {
      data,
      total: result.count,
    };
  });

  const logInfo = useMemo(() => {
    const client = operLog?.client;
    console.log(client);
    return [
      {
        key: 'operId',
        label: '操作ID',
        children: operLog?.operId,
      },
      {
        key: 'moduleName',
        label: '模块标题',
        children: operLog?.moduleName,
      },
      {
        key: 'operType',
        label: '操作类型',
        children: operLog?.operType,
      },
      {
        key: 'ip',
        label: '请求IP',
        children: operLog?.ip,
      },
      {
        key: 'location',
        label: '请求地址',
        children: operLog?.location,
      },
      {
        key: 'status',
        label: '请求地址',
        children: (
          <Tag color={operLog?.status === StatusEnum.NORMAL ? 'green' : 'red'}>
            {operLog?.status === StatusEnum.NORMAL ? '操作成功' : '操作失败'}
          </Tag>
        ),
      },
      {
        key: '请求类型',
        label: '请求类型',
        children: operLog?.method,
      },
      {
        key: 'url',
        label: '请求地址',
        children: operLog?.url,
      },
      {
        key: 'param',
        label: '请求参数',
        children: operLog?.param,
      },
      {
        key: 'jsonResult',
        label: '返回数据',
        children: operLog?.jsonResult,
      },
      {
        key: 'costTime',
        label: '请求时长',
        children: `${operLog?.costTime ?? '--'}ms`,
      },
      {
        key: 'userAgent',
        label: 'userAgent',
        children: (operLog?.client as unknown as IClientInfo)?.userAgent || '--',
      },
    ] as DescriptionsProps['items'];
  }, [operLog]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onDeleteDataByIds = useMemoizedFn(async (ids: string[]) => {
    Modal.confirm({
      title: '注意',
      content: `删除后无法恢复，确定删除指定日志吗？`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await deleteOperLog(ids);
        message.success('删除成功');
        setSelectedRowKeys([]);
        onRefresh();
      },
    });
  });

  const onRefresh = useMemoizedFn((payload: Record<string, any> = {}) => {
    QueryTable.ProviderGlobalDispatch(
      {
        type: QueryTable.ActionEnum.REQUEST_START,
        payload,
      },
      SystemLogLoginTableName,
    );
  });

  const totalColumn = useMemo(() => {
    return [
      ...columns,
      {
        title: '操作',
        fixed: 'right',
        width: 150,
        renderType: 'Operation',
        renderProps: (col: IOperLogItem) => {
          return {
            buttons: [
              {
                label: '详情',
                onClick: async () => {
                  setTrue();
                  setOperLog(col);
                },
              },
              {
                label: '删除',
                onClick: async () => {
                  await onDeleteDataByIds([col.operId]);
                },
              },
            ],
          };
        },
      },
    ] as QueryTableColumnProps;
  }, [columns]);

  return (
    <Card className="m-4 rounded-md">
      <div className="text-base font-semibold mb-4">{t('menu.logs.operation')}</div>
      <QueryTable.Provider
        useQuery={false}
        name={SystemLogLoginTableName}
        requestFn={getRolesTableList}>
        <QueryTable.Filter schema={schema} components={{ Input, Select, DatePicker }} />
        <QueryTable.Table
          columns={totalColumn}
          rowKey="operId"
          scroll={{ x: 1200 }}
          rowSelection={{
            selectedRowKeys,
            onChange: onSelectChange,
          }}
          tabsNode={
            <Button
              disabled={!selectedRowKeys.length}
              color="danger"
              icon={<AiOutlineDelete />}
              onClick={() => {
                onDeleteDataByIds(selectedRowKeys as string[]);
              }}
              variant="outlined">
              批量删除
            </Button>
          }
        />
      </QueryTable.Provider>
      <Drawer
        open={open}
        onClose={setFalse}
        width={800}
        title={`操作日志·${dayjs(operLog?.createTime).format('YYYY-MM-DD HH:mm:ss')}`}>
        <Descriptions
          size="small"
          items={logInfo}
          bordered
          column={1}
          labelStyle={{ width: 120 }}
        />
      </Drawer>
    </Card>
  );
}
