import { Tag } from 'antd';
import type {
  QueryTableColumnProps,
  QueryTableFilterISchemaProperties,
} from 'src/components/QueryTable';
import { StatusEnum } from 'src/api/types/common';
import dayjs from 'dayjs';

export const columns: QueryTableColumnProps = [
  {
    title: '日志ID',
    dataIndex: 'operId',
    key: 'operId',
    width: 180,
  },
  {
    title: '模块标题',
    dataIndex: 'moduleName',
    key: 'moduleName',
    width: 120,
  },
  {
    title: '业务类型',
    dataIndex: 'operType',
    key: 'operType',
    width: 120,
  },
  {
    title: '操作人员',
    dataIndex: 'userName',
    key: 'userName',
    width: 120,
  },
  {
    title: 'IP地址',
    dataIndex: 'ip',
    key: 'ip',
    width: 100,
  },
  {
    title: '登录地址',
    dataIndex: 'location',
    key: 'location',
    width: 150,
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    render: (status: string) => {
      return (
        <Tag color={status === StatusEnum.NORMAL ? 'green' : 'red'}>
          {status === StatusEnum.NORMAL ? '操作成功' : '操作失败'}
        </Tag>
      );
    },
  },
  {
    title: '操作日期',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 200,
    render: (createTime: string) => {
      return dayjs(createTime).format('YYYY-MM-DD HH:mm:ss');
    },
  },
];

export const schema: QueryTableFilterISchemaProperties = {
  moduleName: {
    type: 'string',
    title: '模块标题',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入模块标题',
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
  },
  userName: {
    type: 'string',
    title: '操作人员',
    'x-component': 'Input',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请输入操作人员',
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
  },
  status: {
    type: 'number',
    title: '状态',
    'x-component': 'Select',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: '请选择状态',
      options: [
        { value: StatusEnum.NORMAL, label: '操作成功' },
        { value: StatusEnum.STOP, label: '操作失败' },
      ],
    },
  },
  '[createTime, endTime]': {
    type: 'array',
    title: '操作时间',
    'x-component': 'DatePicker.RangePicker',
    'x-decorator': 'FormItem',
    'x-component-props': {
      placeholder: ['开始时间', '结束时间'],
      format: 'YYYY-MM-DD',
    },
    'x-decorator-props': {
      gridSpan: 1,
    },
  },
};
